import Web3 from 'web3';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA1IAqGYLwYgnb4Lq18EHQ65uGj0CUcz5c",
  authDomain: "blerst.com", 
  projectId: "blerst-28a7d",
  storageBucket: "blerst-28a7d.appspot.com",
  messagingSenderId: "133899505990",
  appId: "1:133899505990:web:26e06ff9abf92823b09547",
  measurementId: "G-3L1RK79CNZ"
};

initializeApp(firebaseConfig);

let web3;
let selectedAccount = null;

// Helper function to get the MetaMask provider if multiple providers are available
const getMetaMaskProvider = () => {
  if (window.ethereum.providers) {
    // Multiple providers detected, find MetaMask specifically
    return window.ethereum.providers.find(provider => provider.isMetaMask);
  }
  // Single provider, return the only available one
  return window.ethereum;
};

// Initialize Web3 and prompt user to connect their wallet
export const initializeWeb3 = async () => {
  const ethereumProvider = getMetaMaskProvider();

  if (ethereumProvider) {
    web3 = new Web3(ethereumProvider);
    try {
      // Request account access if needed
      await ethereumProvider.request({ method: 'eth_requestAccounts' });
      console.log('Wallet connected');
    } catch (error) {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        console.error('User denied wallet connection');
      } else {
        console.error('Unexpected error during wallet connection:', error.message);
      }
    }
  } else {
    console.error('No compatible Ethereum wallet detected. Please install MetaMask or another Ethereum-compatible wallet.');
  }
};

// Function to handle wallet connection
export const connectWallet = async () => {
  try {
    if (!web3) {
      await initializeWeb3();
    }

    if (!web3) {
      console.error('Web3 initialization failed. No wallet found.');
      return null;
    }

    const accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
      console.error('No wallet accounts found. Please connect to a wallet.');
      return null;
    }

    selectedAccount = accounts[0];
    console.log('Connected account:', selectedAccount);
    return selectedAccount;
  } catch (error) {
    if (error.message.includes('User denied account authorization')) {
      console.error('User denied account access.');
    } else if (error.message.includes('Cannot read properties of undefined')) {
      console.error('Ethereum provider is not initialized properly. Please refresh or check your browser extension.');
    } else {
      console.error('Wallet connection failed:', error.message);
    }
    return null;
  }
};


// Function to handle Twitter sign-in
export const signInWithTwitter = async () => {
  const authProvider = new TwitterAuthProvider();

  try {
    const result = await signInWithPopup(getAuth(), authProvider);
    const user = result.user;
    const token = await user.getIdToken();

    return {
      username: result.additionalUserInfo.username,
      profilePicture: user.photoURL,
      accessToken: token,
    };
  } catch (error) {
    console.error('Twitter sign-in failed:', error.message);
    return null;
  }
};

// Function to determine if a user is a Pro user
const checkIsProUser = (address) => {
  const proUserAddresses = [
    '0x710360fF7a4d197e088125b66B6d204759180EA6',
    '0x01c8c92089004bFF57D2740479D9Cf8D441D6bAD',
  ];

  return proUserAddresses.includes(address);
};

// Function to log in the user using their Ethereum account
export const loginUser = async (referralLink) => {
  try {
    const ethAccount = await connectWallet();
    if (!ethAccount) {
      throw new Error('Wallet connection failed. Please connect your wallet.');
    }

    const response = await axios.post('https://blerst.com/api/connect-wallet', {
      address: ethAccount,
      referralLink,
    });

    if (response.status === 200) {
      const data = response.data;
      const isProUser = checkIsProUser(ethAccount);

      const userDetails = {
        ...data,
        address: ethAccount,
        isProUser, // Set the isProUser flag
      };

      localStorage.setItem('userDetails', JSON.stringify(userDetails));
      console.log('User details updated:', userDetails);
      return userDetails;
    } else {
      console.error('Error fetching user details:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user details:', error.message);
    return null;
  }
};

// Function to handle user logout
export const logoutUser = () => {
  selectedAccount = null;
  console.log('User logged out');
  localStorage.removeItem('userDetails');
};

// Utility function to fetch the current connected account
export const getCurrentAccount = () => {
  return selectedAccount;
};

// Utility function to check if the user is authenticated
export const isAuthenticated = () => {
  return !!selectedAccount;
};
