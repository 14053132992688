// outputReducer.js

export const outputReducer = (state, action) => {
  // Define job statuses for consistency
  const JobStatus = {
    QUEUED: 'queued',
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    FAILED: 'failed',
  };

  // Utility function to determine if any jobs are still loading
  const calculateIsLoading = (jobs) => {
    return jobs.some(
      (job) => job.status === JobStatus.QUEUED || job.status === JobStatus.PROCESSING
    );
  };

  switch (action.type) {
    case 'SUBMIT':
      return {
        ...state,
        isLoading: true,
        debugInfo: 'Submitting job to queue...',
      };

    case 'JOB_QUEUED':
      const newJob = {
        jobId: action.jobId,
        jobName: action.jobName,
        queuePosition: action.queuePosition,
        status: JobStatus.QUEUED,
        imageUrl: null,
        promptId: null,
        isProcessing: false,
      };
      return {
        ...state,
        jobs: [newJob, ...state.jobs],
        isLoading: true,
        debugInfo: `Job "${action.jobName}" queued at position ${action.queuePosition}.`,
      };

    case 'UPDATE_JOBS':
      console.log('Action: UPDATE_JOBS', action.jobs);

      const updatedJobs = state.jobs.map((currentJob) => {
        const updatedJob = action.jobs.find((job) => job.jobId === currentJob.jobId);
        if (updatedJob) {
          const hasChanged =
            currentJob.status !== updatedJob.status ||
            currentJob.queuePosition !== updatedJob.queuePosition ||
            currentJob.imageUrl !== updatedJob.imageUrl ||
            currentJob.isProcessing !== updatedJob.isProcessing;
          if (hasChanged) {
            console.log(`Job "${updatedJob.jobId}" updated:`, updatedJob);
            return { ...currentJob, ...updatedJob };
          }
        }
        return currentJob;
      });

      return {
        ...state,
        jobs: updatedJobs,
        isLoading: calculateIsLoading(updatedJobs),
        debugInfo: 'Updated job statuses.',
      };

    case 'ADD_PREVIOUS_IMAGE':
      // Ensure 'imageUrl' is present and correctly formatted
      const newImageUrl =
        typeof action.imageUrl === 'string' ? action.imageUrl : action.imageUrl.image_url || '';
      if (!newImageUrl) {
        console.error('Invalid imageUrl in ADD_PREVIOUS_IMAGE:', action.imageUrl);
        return state;
      }

      const updatedPreviousImages = [newImageUrl, ...state.previousImages]
        .slice(0, 30) // Limit to the latest 30 images
        .filter((url) => url); // Remove any empty strings

      return {
        ...state,
        previousImages: updatedPreviousImages,
        debugInfo: 'Added a new image to previousImages.',
      };

    case 'SET_PREVIOUS_IMAGES':
      // Ensure action.images is an array
      if (!Array.isArray(action.images)) {
        console.error('SET_PREVIOUS_IMAGES expects an array:', action.images);
        return state;
      }

      // Extract 'image_url' from each image object, ensuring consistency
      const limitedImages = action.images
        .slice(-10) // Limit to the latest 10 images
        .map((image, index) => {
          if (typeof image === 'string') return image; // If already a string
          if (image.image_url) return image.image_url; // Extract 'image_url'
          console.warn(`Image at index ${index} is missing 'image_url':`, image);
          return ''; // Fallback to empty string if 'image_url' is missing
        })
        .filter((url) => url); // Remove any empty strings

      return {
        ...state,
        previousImages: limitedImages,
        debugInfo: 'Set previous images from user history.',
      };

    case 'CLEAR_PREVIOUS_IMAGES':
      return {
        ...state,
        previousImages: [],
        debugInfo: 'Cleared previous images on logout.',
      };

    case 'UPDATE_IMAGE_URL':
      return {
        ...state,
        imageUrl: action.imageUrl,
        debugInfo: 'Updated current image URL.',
      };

    case 'ERROR':
      const errorJobIndex = state.jobs.findIndex((job) => job.jobId === action.jobId);
      if (errorJobIndex !== -1) {
        const updatedErrorJob = {
          ...state.jobs[errorJobIndex],
          status: JobStatus.FAILED,
          debugInfo: action.message,
        };
        const updatedJobsError = [...state.jobs];
        updatedJobsError[errorJobIndex] = updatedErrorJob;
        return {
          ...state,
          jobs: updatedJobsError,
          isLoading: calculateIsLoading(updatedJobsError),
          debugInfo: `Job "${action.jobId}" failed: ${action.message}`,
        };
      }
      return state;

    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
};
