import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import axios from 'axios';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NetworkStatus from './components/NetworkStatus';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import Header from './components/Header';
import { outputReducer } from './components/OutputReducer';
import { logoutUser, loginUser, isAuthenticated } from './components/Auth';
import io from 'socket.io-client';
import AuthModal from './components/AuthModal'; // Import AuthModal

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
}

function CollapseButton({ isPanelCollapsed, togglePanel }) {
  return (
    <button
      className={`collapseButton ${isPanelCollapsed ? 'active' : ''}`}
      onClick={togglePanel}
    >
      {isPanelCollapsed ? 'Show Jobs' : 'Hide Jobs'}
    </button>
  );
}

function App() {
  const isMobile = useIsMobile();
  const [mode, setMode] = useState('Standard');
  const [batchSize, setBatchSize] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPanel, setShowPanel] = useState(!isMobile);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [socket, setSocket] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [user, setUser] = useState({
    isAuthenticated: false,
    isConnecting: false,
    address: null, // ETH address will be stored here
    isProUser: false,
  });

  const [inputState, setInputState] = useState({
    clothing: '',
    scene: '',
    background: '',
    text: '',
    prompt: '',
    width: 512,
    height: 512,
    lora: 'None',
    instruction: null,
  });

  const [outputState, dispatchOutput] = useReducer(outputReducer, {
    imageUrl: '',
    previousImages: [],
    debugInfo: '',
    isLoading: false,
    queuePosition: null,
    totalJobs: null,
    jobId: null,
    promptId: null,
    jobs: [],
  });

  // Inside App component
  const outputStateRef = useRef(outputState);
  useEffect(() => {
    outputStateRef.current = outputState;
  }, [outputState]);

  useEffect(() => {
    if (socket && user.address) {
      socket.emit('authenticate', { uid: user.address });
      console.log(`Authenticated with uid ${user.address}`);
    }
  }, [socket, user.address]);  

  // WebSocket setup for queue updates
  useEffect(() => {
    if (socket) {
      const handleQueueUpdate = (updatedQueue) => {
        const jobsToUpdate = updatedQueue.map(({ jobId, queuePosition }) => ({
          jobId,
          queuePosition,
        }));
        dispatchOutput({ type: 'UPDATE_JOBS', jobs: jobsToUpdate });
      };

      socket.on('queue_update', handleQueueUpdate);

      return () => {
        socket.off('queue_update', handleQueueUpdate);
      };
    }
  }, [socket]);

  const socketRef = useRef(null);

  useEffect(() => {
    if (socketRef.current) return;

    const newSocket = io('https://api.onlyjpegs.com', {
      withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
      transports: ['websocket'],
    });

    socketRef.current = newSocket;

    const handleConnect = () => {
      toast.success('Connected to the Generator');

      // Emit event to authenticate after connecting
      if (user.address) {
        newSocket.emit('authenticate', { uid: user.address });
        console.log(`Authenticated with uid ${user.address}`);
      }
    };

    const handleDisconnect = () => {
      toast.warn('Disconnected from the Generator.');
    };

    const handleJobUpdate = (data) => {
      const { jobId, status, image_url, queuePosition, isProcessing } = data;
      console.log('Received job update:', data); // Add this line
      const job = outputStateRef.current.jobs.find((j) => j.jobId === jobId);

      if (!job) {
        console.warn(`Job with ID ${jobId} not found.`);
        return;
      }

      const updatedJob = {
        jobId: jobId,
        status: status || job.status,
        imageUrl: image_url || job.imageUrl,
        queuePosition: queuePosition !== undefined ? queuePosition : job.queuePosition,
        isProcessing: isProcessing !== undefined ? isProcessing : job.isProcessing,
      };

      if (status === 'completed' && image_url && job.status !== 'completed') {
        toast.success(`Your image for job "${job.jobName}" is ready!`);
        handleNewImage(image_url);
      }

      if (status === 'failed' && job.status !== 'failed') {
        toast.error(`Job "${job.jobName}" failed.`);
      }

      dispatchOutput({ type: 'UPDATE_JOBS', jobs: [updatedJob] });

      if (updatedJob.status === 'completed' || updatedJob.status === 'failed') {
        setCurrentJobId(null);
      }
    };

    const handleConnectError = (error) => {
      toast.success('Disconnected to WebSocket server.');
      console.error('Connection Error:', error);
    };

    const handleConnectTimeout = (timeout) => {
      console.warn('Connection Timeout:', timeout);
    };

    const handleReconnect = (attemptNumber) => {
      console.info(`Reconnected to server after ${attemptNumber} attempts.`);
      toast.success('Reconnected to WebSocket server.');
    };

    const handleReconnectFailed = () => {
      console.error('Failed to reconnect to WebSocket server.');
      toast.error('Failed to reconnect to WebSocket server.');
    };

    // Register event listeners
    newSocket.on('connect', handleConnect);
    newSocket.on('disconnect', handleDisconnect);
    newSocket.on('job_update', handleJobUpdate);
    newSocket.on('connect_error', handleConnectError);
    newSocket.on('connect_timeout', handleConnectTimeout);
    newSocket.on('reconnect', handleReconnect);
    newSocket.on('reconnect_failed', handleReconnectFailed);

    setSocket(newSocket);

    return () => {
      newSocket.off('connect', handleConnect);
      newSocket.off('disconnect', handleDisconnect);
      newSocket.off('job_update', handleJobUpdate);
      newSocket.off('connect_error', handleConnectError);
      newSocket.off('connect_timeout', handleConnectTimeout);
      newSocket.off('reconnect', handleReconnect);
      newSocket.off('reconnect_failed', handleReconnectFailed);
      newSocket.disconnect();
    };
  }, []);

  const handleSingleJobSubmit = useCallback(
    async (promptToUse) => {
      if (!user.isAuthenticated) {
        setIsAuthModalOpen(true); // Open the Connect Wallet modal
        return;
      }

      setIsSubmitting(true);

      try {
        if (!socket) {
          throw new Error('WebSocket connection is not established.');
        }

        const clientSid = socket.id;

        const requestData = {
          prompt: promptToUse,
          width: inputState.width,
          height: inputState.height,
          lora: inputState.lora === 'None' ? null : inputState.lora,
          instruction: inputState.instruction || null,
          sid: clientSid,
        };

        if (user.address) {
          requestData.uid = user.address;
        }

        const response = await axios.post(
          'https://api.onlyjpegs.com/submit-prompt',
          requestData,
          {
            withCredentials: true,
          }
        );

        const jobId = response.data.jobId;
        if (jobId) {
          const jobName = generateJobName(promptToUse, jobId, mode);

          dispatchOutput({
            type: 'JOB_QUEUED',
            jobId: jobId,
            jobName: jobName,
            queuePosition: response.data.queuePosition,
          });

          setShowPanel(true);

          setCurrentJobId(jobId);
          setIsSubmitting(false);
        } else {
          toast.error('Failed to retrieve job ID from backend.');
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error during job submission:', error);
        dispatchOutput({ type: 'ERROR', jobId: null, message: error.message });
        toast.error('Failed to submit job: ' + error.message);
        setIsSubmitting(false);
      }
    },
    [inputState, dispatchOutput, mode, user.address, socket]
  );

  const handleInputChange = useCallback((field, value) => {
    setInputState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleNewImage = (newImageUrl) => {
    dispatchOutput({
      type: 'ADD_PREVIOUS_IMAGE',
      imageUrl: newImageUrl,
    });
  };

  const generateJobName = (prompt, jobId, type = 'Job') => {
    const shortenedPrompt = prompt.split(' ').slice(0, 3).join('_');
    return `${shortenedPrompt}`;
  };

  const handleBatchSubmit = useCallback(async () => {
    if (!user.isAuthenticated) {
      setIsAuthModalOpen(true);
      return;
    }

    setIsSubmitting(true);
    dispatchOutput({ type: 'SUBMIT' });

    try {
      const defaultPrompt = 'Random prompt for Blerst.';

      if (mode === 'Pro') {
        const prompts = (inputState.prompt || defaultPrompt)
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line !== '');

        for (const prompt of prompts) {
          for (let i = 0; i < batchSize; i++) {
            await handleSingleJobSubmit(prompt);
          }
        }
      } else {
        for (let i = 0; i < batchSize; i++) {
          console.log(
            'Submitting standard prompt:',
            inputState.prompt || defaultPrompt
          );
          await handleSingleJobSubmit(inputState.prompt || defaultPrompt);
        }
      }
    } catch (error) {
      console.error('Error submitting job:', error);
      toast.error('An error occurred during job submission.');
    } finally {
      setIsSubmitting(false);
    }
  }, [
    user.isAuthenticated,
    batchSize,
    handleSingleJobSubmit,
    mode,
    inputState.prompt,
    inputState.instruction,
    dispatchOutput,
  ]);

  const fetchUserImages = useCallback(
    async (uid) => {
      try {
        const response = await axios.get(
          `https://api.onlyjpegs.com/user-images/${uid}`,
          {
            withCredentials: true,
          }
        );
        console.log('Fetched user images:', response.data.images); // Add this line
        if (response.data.images) {
          dispatchOutput({
            type: 'SET_PREVIOUS_IMAGES',
            images: response.data.images,
          });
        }
      } catch (error) {
        console.error(`Failed to fetch images for user ${uid}:`, error);
      }
    },
    [dispatchOutput]
  );
  

  // Updated handleLogin to accept userData object
  const handleLogin = (userData) => {
    if (userData && userData.address) {
      setUser({
        isAuthenticated: true,
        isConnecting: false,
        address: userData.address,
        isProUser: userData.isProUser || false,
      });
      setIsAuthModalOpen(false);
      dispatchOutput({ type: 'CLEAR_PREVIOUS_IMAGES' });
      fetchUserImages(userData.address);
      toast.success('Successfully logged in!');
    } else {
      console.error("Invalid user data received during login.");
      toast.error("Invalid user data received. Please try logging in again.");
    }
  };

  const handleLogout = () => {
    logoutUser();
    setUser({
      isAuthenticated: false,
      isConnecting: false,
      address: null,
      isProUser: false,
    });
    dispatchOutput({
      type: 'CLEAR_PREVIOUS_IMAGES',
    });
    toast.info('Successfully logged out.');
  };

  const togglePanel = () => {
    if (isMobile) {
      setIsPanelCollapsed(!isPanelCollapsed);
    }
  };

  const openAuthModal = () => {
    setIsAuthModalOpen(true);
  };

  return (
    <div className="appContainer">
      <Header
        user={user}
        handleLogin={openAuthModal} // Changed to open the AuthModal
        handleLogout={handleLogout}
      />
      <div className="mainContent">
        <NetworkStatus />
        <LeftPanel
          mode={mode}
          setMode={setMode}
          inputState={inputState}
          handleInputChange={handleInputChange}
          handleSubmit={handleBatchSubmit}
          batchSize={batchSize}
          setBatchSize={setBatchSize}
          outputState={outputState}
          isSubmitting={isSubmitting}
          isProUser={user.isProUser}
        />
        {isMobile && (
          <CollapseButton
            isPanelCollapsed={isPanelCollapsed}
            togglePanel={togglePanel}
            className={modalOpen ? 'modalOpen' : ''}
          />
        )}
        <div
          className={`rightPanelContainer ${
            isPanelCollapsed && isMobile ? 'collapsed' : ''
          }`}
        >
          <RightPanel
            outputState={outputState}
            dispatchOutput={dispatchOutput}
            allJobs={outputState.jobs}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={() => setIsAuthModalOpen(false)}
          setUser={handleLogin} // Now correctly handles a user object
        />
      </div>
    </div>
  );
}

export default App;