// AuthModal.jsx
import React, { useState } from 'react';
import styles from './styles/AuthModal.module.css';
import { loginUser } from './Auth';

// MetaMask and Rabby wallet logos
const metamaskLogo = 'https://cdn.iconscout.com/icon/free/png-256/metamask-2728406-2261817.png'; 
const rabbyLogo = 'https://mediaresource.sfo2.digitaloceanspaces.com/wp-content/uploads/2024/04/28114737/rabby-logo-A5F793A6F6-seeklogo.com.png'; 

const AuthModal = ({ isOpen, onClose, setUser }) => {
  const [error, setError] = useState(null);

  const handleWalletConnect = async (walletType) => {
    try {
      const userData = await loginUser(walletType);
      
      if (userData && userData.address) {
        setUser(userData); // Pass raw userData to handleLogin
        onClose(); // Close modal only on successful login
      } else {
        console.error("Invalid user data received during login.");
        setError('Invalid user data received. Please try again.');
      }
    } catch (error) {
      console.error("Wallet connection failed:", error);
      setError('Failed to connect. Please try again.');
      // Do not close the modal on failure
    }
  };

  const handleOverlayClick = (event) => {
    // If the user clicks directly on the overlay (outside the modal), close the modal
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h2>Select Wallet</h2>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <button className={styles.walletButton} onClick={() => handleWalletConnect('MetaMask')}>
          <img src={metamaskLogo} alt="MetaMask Logo" />
          Connect with MetaMask
        </button>
        <button className={styles.walletButton} onClick={() => handleWalletConnect('Rabby')}>
          <img src={rabbyLogo} alt="Rabby Logo" />
          Connect with Rabby Wallet
        </button>
        <button onClick={onClose} className={styles.cancelButton}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AuthModal;
