import React, { useCallback, useState, useMemo } from 'react';
import ImageDisplay from './ImageDisplay';
import JobsSection from './JobsSection';
import { saveAs } from 'file-saver';
import styles from '../components/styles/RightPanel.module.css';

const useImageHandler = ({ outputState, setModalOpen }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const openModal = useCallback((imageIndex) => {
        setCurrentImageIndex(imageIndex);  // Set the current image index
        setModalOpen(true);
    }, [setModalOpen]);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const saveImage = useCallback((imageUrl) => {
        if (imageUrl) {
            const fileName = getFileName(imageUrl);
            saveAs(imageUrl, fileName); // Use FileSaver.js to save the image
        }
    }, []);

    const shareImage = useCallback(async (imageUrl) => {
        if (!imageUrl) return;
    
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Check out this image',
              url: imageUrl,
            });
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else if (navigator.clipboard) {
          try {
            await navigator.clipboard.writeText(imageUrl);
            alert('Image URL copied to clipboard!');
          } catch (error) {
            console.error('Failed to copy:', error);
            alert('Failed to copy the image URL.');
          }
        } else {
          // Fallback for very old browsers
          const textarea = document.createElement('textarea');
          textarea.value = imageUrl;
          textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
          document.body.appendChild(textarea);
          textarea.focus();
          textarea.select();
    
          try {
            document.execCommand('copy');
            alert('Image URL copied to clipboard!');
          } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            alert('Failed to copy the image URL.');
          }
    
          document.body.removeChild(textarea);
        }
      }, []);

    const getFileName = useCallback((str) => {
        return str.substring(str.lastIndexOf('/') + 1);
    }, []);

    const navigateImages = useCallback((direction) => {
        if (currentImageIndex !== null && outputState.previousImages.length > 0) {
            const nextIndex = (currentImageIndex + direction + outputState.previousImages.length) % outputState.previousImages.length;
            setCurrentImageIndex(nextIndex);
        }
    }, [currentImageIndex, outputState.previousImages.length]);

    const currentImageUrl = useMemo(() => {
        return currentImageIndex !== null ? outputState.previousImages[currentImageIndex] : null;
    }, [currentImageIndex, outputState.previousImages]);

    return { openModal, closeModal, saveImage, shareImage, currentImageUrl, setCurrentImageIndex };
};

const RightPanel = React.memo(({ outputState, dispatchOutput, allJobs, modalOpen, setModalOpen }) => {
    const { openModal, closeModal, saveImage, shareImage, navigateImages, currentImageUrl } = useImageHandler({ outputState, setModalOpen });

    return (
        <div className={styles.rightPanel}>
            {/* Modal is always rendered but its visibility is controlled */}
            <div 
                className={`${styles.modalOverlay} ${modalOpen ? styles.showModal : styles.hideModal}`} 
                onClick={closeModal}
                style={{ display: modalOpen ? 'block' : 'none' }}
            >
                <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                    <button className={`${styles.modalButton} ${styles.closeButton}`} onClick={closeModal}>Close</button>
                    <ImageDisplay imageUrl={currentImageUrl} />
                    <div className={styles.navigation}>
                        <button
                            className={styles.modalButton}
                            onClick={() => navigateImages(-1)}
                            aria-label="Previous image"
                        >
                            Previous
                        </button>
                        <button
                            className={`${styles.modalButton} ${styles.saveButton}`}
                            onClick={() => saveImage(currentImageUrl)}
                            aria-label="Save image"
                        >
                            Save
                        </button>
                        <button
                            className={`${styles.modalButton} ${styles.shareButton}`}
                            onClick={() => shareImage(currentImageUrl)}
                            aria-label="Share image"
                            >
                            Share
                            </button>
                        <button
                            className={styles.modalButton}
                            onClick={() => navigateImages(1)}
                            aria-label="Next image"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <JobsSection jobs={allJobs} saveImage={saveImage} openModal={index => openModal(index)} />

            {outputState.previousImages.length > 0 && (
                <div className={styles.thumbnails}>
                    <h2>Previous Images</h2>
                    <div className={styles.thumbnailContainer}>
                    {outputState.previousImages.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            className={styles.thumbnail}
                            alt={`Thumbnail ${index + 1}`}
                            onClick={() => openModal(index)}  // Pass the correct index to openModal
                        />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});

export default RightPanel;
