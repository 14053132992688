import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import styles from '../components/styles/RightPanel.module.css';

const JobsSection = ({ jobs, saveImage, openModal }) => {
  return (
    <div className={styles.jobsSection}>
      <h2>Job Feed</h2>
      <div className={styles.jobsFeed}>
        {jobs.map((job, index) => {
          const status = job.status?.toLowerCase() || 'unknown'; // Use 'unknown' if status is missing

          return (
            <div 
              key={index} 
              className={`${styles.jobCard} ${status === 'completed' ? styles.completed : 
                                                status === 'processing' ? styles.processing : 
                                                styles.queued}`}
            >
              <p><strong>Job:</strong> {job.jobName || 'Unnamed Job'}</p> 
              {status !== 'completed' && (
                <p><strong>Status:</strong> {status}</p>
              )}
              {status !== 'completed' && job.queuePosition !== null && job.queuePosition !== 0 && (
                <p><strong>Queue Position:</strong> {job.queuePosition}</p>
              )}
              {job.imageUrl && (
                <div className={styles.jobImageContainer}>
                  <img 
                    src={job.imageUrl} 
                    alt="Generated" 
                    className={styles.jobImage}
                    onClick={() => openModal(index)}
                  />
                  <button
                    className={styles.saveButtonOverlay}
                    onClick={() => saveImage(job.imageUrl)}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobsSection;
