// Header.jsx
import React from 'react';
import styles from './styles/Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Header = ({ user, handleLogin, handleLogout }) => {
  // Function to format the address to show only the first and last 4 characters
  const formatAddress = (address) => {
    if (!address) return '';
    const addressStr = String(address);
    return `${addressStr.slice(0, 4)}...${addressStr.slice(-4)}`;
  };

  return (
    <div className={styles.header}>
      <a href="http://blerst.com/" className={styles.link} target="_blank" rel="noopener noreferrer">
        <div className={styles.title}>
          <FontAwesomeIcon icon={faHome} className={styles.icon} />
          Home
        </div>
      </a>
      <div className={styles.authContainer}>
        {user.isAuthenticated ? (
          <>
            <span className={styles.welcomeText}>
              {user?.username ? `Welcome, ${user.username}` : `Welcome, ${formatAddress(user.address)}`}
            </span>
            <button onClick={handleLogout} className={styles.button}>Logout</button>
          </>
        ) : (
          <button onClick={() => handleLogin()} className={styles.button}>
            {user?.isConnecting ? 'Connecting...' : 'Connect Wallet'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
